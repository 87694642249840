import { Link } from "gatsby"
import React from "react"

import arcelik from "../assets/img/companys/21.png"
import toyota from "../assets/img/companys/toyota-logo.png"
import tcdd from "../assets/img/companys/15.png"
import popupsmart from "../assets/img/companys/20.png"
import nills from "../assets/img/companys/35.png"
import kentpark from "../assets/img/companys/36.png"
import divanev from "../assets/img/companys/divan-ev.png"





const CompanysSEO = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={toyota} />
        </div>
        <div className="company-box">
            <img src={arcelik} />
        </div>
        <div className="company-box">
            <img src={tcdd} />
        </div>
        <div className="company-box">
            <img src={popupsmart} />
        </div>
        <div className="company-box">
            <img src={divanev} />
        </div>
        <div className="company-box">
            <img src={nills} />
        </div>
        <div className="company-box">
            <img src={kentpark} />
        </div>
        
    </div>
)

export default CompanysSEO
