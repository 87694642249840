import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import rightArrow from "../assets/img/right-arrow.png";
import googleAds from "../assets/img/google-img.png"
import sefasone from "../assets/img/sefastone-logo.png"
import wave from "../assets/img/wave.png"
import seoAudits from "../assets/img/seo-audits.png"
import onePageSeo from "../assets/img/one-page-seo.png"
import offPageSeo from "../assets/img/off-page-seo.png"
import seoTools from "../assets/img/seo-tools.png"
import Teams from "../components/teams/seo-team"
import SeoSss from "../components/sss/seo-sss"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/seo-companys"
import liva from "../assets/img/companys/17.png"
import tcdd from "../assets/img/companys/15.png"
import enerjisa from "../assets/img/companys/38.png"
import kuruyemis from "../assets/img/companys/40.png"


class Seo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
      value: "",
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    e.preventDefault()

    this.setState({ value: e.target.value })
  }
  showModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.add("active-modal")
  }
  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector(".check-modal").classList.remove("active-modal")
  }
  handleSubmit(e) {
    e.preventDefault()
    const title = document.title.split("|", 1).toString()
    const response = fetch(
      "https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7",
      {
        method: "POST",
        body: JSON.stringify({
          text_327649: title,
          text_204202: document.querySelector("#site").value,
          text_775908: document.querySelector("#name").value,
          text_532192: document.querySelector("#email").value,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
    response.then(res => {
      if (res.status == 200) {
        document.querySelector(".status").innerHTML =
          "Mesajınız başarıyla gönderilmiştir."
      } else {
        document.querySelector(".status").innerHTML = "Bir hata oluştu."
      }
    })
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Search Engine Optimization"
          description="As we have done with more than 125 brands so far, we enable brands to rise higher among the search results and increase the organic sales."
        />
        <div className="seo-service container">
          <div className="seo-service__head">
            <div className="col-md-6 noPadding">
              <h1>
                Search Engine <br />
                Optimization
              </h1>
              <span className="red-head">SERVICES</span>
              <p>
                As we have done with more than 125 brands so far, we enable
                brands to rise higher among the search results and thus
                increasing organic sales on Google and other search engines.
              </p>
              <input
                type="text"
                className="pink-input"
                placeholder="Enter your website."
                value={this.state.value}
                onChange={this.handleChange}
              />
              <button
                id="seo-button"
                className="red-button"
                onClick={e => this.showModal()}
              >
                Free SEO Checkup <img alt="alt text" src={rightArrow}></img>
              </button>
            </div>
            <div
              className="col-md-6 seo-top-right"
              
            >
              <img
                alt="alt text"
                id="seo-page-img"
                src={googleAds}
                style={{ width: "100%" }}
              />{" "}
            </div>
            <div className="check-modal">
              <span onClick={e => this.closeModal()}>X</span>
              <h2 className="form-head">Fill the form</h2>
              <p className="form-desc">
              Enter your information in the fields to get information, and we will get back to you as soon as possible.
              </p>
              <div className="check-modal_container">
                <form onSubmit={this.handleSubmit}>
                  <input
                    type="text"
                    name="text_204202"
                    id="site"
                    required
                    value={this.state.value}
                    onChange={this.handleChange}
                    autoFocus={true}
                    placeholder="Enter your website."
                  />
                  <input
                    type="text"
                    id="name"
                    required
                    name="text_775908"
                    placeholder="Enter your name"
                  />
                  <input
                    type="email"
                    id="email"
                    required
                    name="text_532192"
                    placeholder="Enter yor email"
                  />
                  <button>SEND</button>
                  <div className="status"></div>
                </form>
                <img alt="alt text" src={checkModal} />
              </div>
            </div>
          </div>
          <div className="studies">
            <p>
              With our proven SEO strategies, we enabled brands to send 81% more
              traffic annually to the website.
            </p>
            <div>
              <span className="red-head">SUCCESS STORIES</span>
            </div>
          </div>
         {/* <div className="clients-customers">
            <div>
              <span>+60k</span>
              <p>Organic traffic acquired monthly.</p>
              <Link to="/success-stories/liva-pastacilik/">
                Check out the details of the campaign
              </Link>
              <br />
              <img alt="alt text" src={liva}></img>
            </div>
            <div>
              <span>+%125</span>
              <p>Organic traffic growth.</p>
              <Link to="/success-stories/tcdd-tasimacilik/">
                Check out the details of the campaign
              </Link>
              <br />
              <img alt="alt text" src={tcdd}></img>
            </div>
            <div>
                <span>%34</span>
                <p>Organic traffic increase in annual return</p>
                <Link to="/success-stories/kuruyemis-borsasi/"> Check out the details of the campaign</Link><br />
                <img alt="alt text" src={kuruyemis}></img>
            </div>
            <div>
                <span>8.2k</span>
                <p>Average number of monthly organic keywords</p>
                <img alt="alt text" src={enerjisa}></img>
            </div>
          </div>*/}
        </div>
        <div className="wave">
          <img alt="alt text" src={wave}></img>
        </div>

        <div className="seo-referances container">
          <h2>Our SEO References</h2>
          <p>Some of the featured brands that are our references.</p>
          <Companys />
    </div>

        <div className="seo-process py60 container">
          <h2>SEO Process</h2>
          <div className="circles">
            <div className="circle">
              <h3>
                <a href="#seo-audits">
                  SEO Audit & <br />
                  Strategizing
                </a>
              </h3>
              <p>
                An SEO strategy that is consciously created gives better
                results. We start each digital campaign with an audit that
                includes technicality, visibility and optimization analysis.
              </p>
            </div>
            <svg 
              id="right-chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="8.51"
              height="13.5"
              viewBox="0 0 8.51 13.5"
            >
              <path
                id="Path_449"
                data-name="Path 449"
                d="M90.411,5.97,84.761.329a1.088,1.088,0,0,0-1.56,0l-.659.65a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l4.211,4.2-4.211,4.211a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l.659.65a1.109,1.109,0,0,0,1.56,0l5.65-5.641a1.109,1.109,0,0,0,.321-.789A1.061,1.061,0,0,0,90.411,5.97Z"
                transform="translate(-82.222 0)"
                fill="#ea4f6a"
              />
            </svg>

            <div className="circle">
              <h3>
                <a href="#on-pageSeo">On-Page SEO</a>
              </h3>
              <p>
                We are optimizing your web pages. Optimization includes
                technical optimization and user-oriented optimization. This is
                done in order to rank high in the Google search result rankings
                and achieve quality traffic.
              </p>
            </div>
            <svg
              id="right-chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="8.51"
              height="13.5"
              viewBox="0 0 8.51 13.5"
            >
              <path
                id="Path_449"
                data-name="Path 449"
                d="M90.411,5.97,84.761.329a1.088,1.088,0,0,0-1.56,0l-.659.65a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l4.211,4.2-4.211,4.211a1.061,1.061,0,0,0-.321.78,1.109,1.109,0,0,0,.321.789l.659.65a1.109,1.109,0,0,0,1.56,0l5.65-5.641a1.109,1.109,0,0,0,.321-.789A1.061,1.061,0,0,0,90.411,5.97Z"
                transform="translate(-82.222 0)"
                fill="#ea4f6a"
              />
            </svg>

            <div className="circle">
              <h3>
                <a href="#off-pageSeo">Off-Page SEO</a>
              </h3>
              <p>
                We also do SEO work outside the website to improve the Google
                search result ranking and increase organic traffic.
              </p>
            </div>
          </div>
        </div>

        <div className="seo-services container">
          <div className="seo-services_head">
            <h2>
              3 fundamental ways to grow <br />
              your brand with SEO
            </h2>
            <span className="red-head">SEO SERVICES</span> <br />
            <svg
              className="flatssvg"
              style={{ margin: "30px 0" }}
              xmlns="http://www.w3.org/2000/svg"
              
              height="auto"
              viewBox="0 0 1265.429 742.91"
            >
              <g
                id="Term-_x26_-Condition"
                transform="translate(-478.903 704.166)"
              >
                <g id="Beckgorund" transform="translate(478.903 36.59)">
                  <path
                    id="Shape"
                    d="M379-300.893H1643.706V-302.7H379Z"
                    transform="translate(-379 302.7)"
                  />
                </g>
                <g id="Element" transform="translate(478 -864.964)">
                  <path
                    id="Shape_1_"
                    d="M1609,413.174V-36.7h153.572V413.174Z"
                    transform="translate(-497.866 276.994)"
                    fill="#ea4f6a"
                  />
                  <path
                    id="Shape_2_"
                    d="M1666.748,14l.09,405.158H1559.79L1559.7,14Z"
                    transform="translate(-493.101 272.095)"
                  />
                  <path
                    id="Shape_3_"
                    d="M1604.487,16.5V417.141H1562.3V16.5Z"
                    transform="translate(-493.352 271.853)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_4_"
                    d="M1216.787,494V676.931H1128.8V494Z"
                    transform="translate(-451.46 225.708)"
                  />
                  <path
                    id="Shape_5_"
                    d="M1131.2,496.5V674.914h83.651V496.5Z"
                    transform="translate(-451.692 225.466)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_6_"
                    d="M1129,693.048l85.819-39.748v39.748Z"
                    transform="translate(-451.479 210.313)"
                  />
                  <path
                    id="Shape_7_"
                    d="M1686.729,355.422l25.927-16.622,21.681,12.286,13.731,33.063V662.836H1447.7l.09-217.258H1556.1Z"
                    transform="translate(-482.278 240.706)"
                  />
                  <path
                    id="Shape_8_"
                    d="M1688.245,357.6l-131.258,90.517H1450.3V660.858h295.851V384.881l-13.37-31.889L1712.907,341.7Z"
                    transform="translate(-482.529 240.426)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_9_"
                    d="M1449,682.964,1747.109,558.3V682.964Z"
                    transform="translate(-482.403 219.494)"
                    stroke="#000"
                    stroke-width="2.5"
                  />
                  <path
                    id="Shape_13_"
                    d="M1609,16.3h60.525l-.9,349.6H1609Z"
                    transform="translate(-497.866 271.872)"
                    fill="#111"
                  />
                </g>
                <g id="Term" transform="translate(903.48 -704.166)">
                  <path
                    id="Shape_14_"
                    d="M627.424-3.39c-5.51-.542-9.937-4.155-13.189-10.75-4.969-9.937,19.784-28,1.626-50.317C597.7-86.68,578.733-85.867,583.7-112.245s24.752-48.6,13.189-74.166-14-37.128-28.094-42.006-18.158-28.817-48.691-27.191q-30.488,1.626-120.508,22.223L380.626-74.3c-3.884,8.763-.813,18.429,9.124,28.817,14.815,15.628,3.342,27.191,3.342,37.941s17.345,37.941,61.88,18.971S493.727-13.327,520.2-3.39c26.378,9.937,25.565,4.968,42.1-1.626S599.42,2.392,610.17,3.2C617.216,3.747,623,1.579,627.424-3.39Z"
                    transform="translate(-378.997 298.157)"
                    fill="#ea4f6a"
                  />
                  <path
                    id="Shape_15_"
                    d="M463.649-302.7A26.64,26.64,0,0,1,490.3-276.051,26.64,26.64,0,0,1,463.649-249.4,26.64,26.64,0,0,1,437-276.051,26.64,26.64,0,0,1,463.649-302.7Zm0,12.015a14.631,14.631,0,0,0-14.634,14.634,14.689,14.689,0,0,0,14.634,14.634,14.689,14.689,0,0,0,14.634-14.634A14.631,14.631,0,0,0,463.649-290.685Z"
                    transform="translate(-384.602 302.7)"
                  />
                  <path
                    id="Shape_16_"
                    d="M394.434-256H519.1a14.631,14.631,0,0,1,14.634,14.634V-77.857A14.631,14.631,0,0,1,519.1-63.223H394.434A14.631,14.631,0,0,1,379.8-77.857V-241.366A14.631,14.631,0,0,1,394.434-256Z"
                    transform="translate(-379.074 298.187)"
                  />
                  <path
                    id="Shape_17_"
                    d="M394.666-253.5A12.491,12.491,0,0,0,382.2-241.034V-77.525a12.491,12.491,0,0,0,12.466,12.466H519.33A12.491,12.491,0,0,0,531.8-77.525V-241.034A12.491,12.491,0,0,0,519.33-253.5Z"
                    transform="translate(-379.306 297.945)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_18_"
                    d="M522.052-232V-74.363H389.8V-232Zm-130.084,2.258v153.12H519.794v-153.12Z"
                    transform="translate(-380.041 295.868)"
                  />
                  <path
                    id="Shape_19_"
                    d="M435.963-208v25.746H405.7V-208Zm-28,2.258v21.229H433.7v-21.229Z"
                    transform="translate(-381.577 293.548)"
                  />
                  <path
                    id="Shape_20_"
                    d="M411-196.286l4.065-2.62,5.42,5.149,9.485-8.943,2.71,2.529-12.2,12.828Z"
                    transform="translate(-382.09 293.036)"
                    fill="#27ad55"
                  />
                  <path
                    id="Shape_21_"
                    d="M435.963-157v25.746H405.7V-157Zm-28,2.258v21.229H433.7v-21.229Z"
                    transform="translate(-381.577 288.62)"
                  />
                  <path
                    id="Shape_22_"
                    d="M411-144.286l4.065-2.62,5.42,5.149,9.485-8.943,2.71,2.529-12.2,12.828Z"
                    transform="translate(-382.09 288.011)"
                    fill="#27ad55"
                  />
                  <path
                    id="Shape_23_"
                    d="M435.963-106v25.746H405.7V-106Zm-28,2.258v21.229H433.7v-21.229Z"
                    transform="translate(-381.577 283.691)"
                  />
                  <path
                    id="Shape_24_"
                    d="M445-99.7h39.748v2.71H445Z"
                    transform="translate(-385.375 283.082)"
                  />
                  <path
                    id="Shape-Copy"
                    d="M445-150.7h39.748v2.71H445Z"
                    transform="translate(-385.375 288.011)"
                  />
                  <path
                    id="Shape-Copy-3"
                    d="M445-202.7h39.748v2.71H445Z"
                    transform="translate(-385.375 293.036)"
                  />
                  <path
                    id="Shape_25_"
                    d="M445-88.7h66.849v2.71H445Z"
                    transform="translate(-385.375 282.019)"
                  />
                  <path
                    id="Shape-Copy-2"
                    d="M445-139.7h66.849v2.71H445Z"
                    transform="translate(-385.375 286.948)"
                  />
                  <path
                    id="Shape-Copy-4"
                    d="M445-191.7h66.849v2.71H445Z"
                    transform="translate(-385.375 291.973)"
                  />
                  <path
                    id="Shape_26_"
                    d="M411-93.286l4.065-2.62,5.42,5.149,9.485-8.943,2.71,2.529-12.2,12.828Z"
                    transform="translate(-382.09 283.082)"
                    fill="#27ad55"
                  />
                  <path
                    id="Shape_27_"
                    d="M503.981-264v24.842H417.8V-264Z"
                    transform="translate(-382.747 298.96)"
                  />
                  <path
                    id="Shape_28_"
                    d="M420.2-261.5v20.326h81.845V-261.5Z"
                    transform="translate(-382.979 298.719)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_29_"
                    d="M428-223.7h67.752v4.517H428Z"
                    transform="translate(-383.732 295.066)"
                    fill="#050505"
                  />
                </g>
                <g id="Plant" transform="translate(479.53 -243.904)">
                  <path
                    id="Shape_30_"
                    d="M405.355-93.568h15l.361-.361c9.3-10.75,12.2-22.313,8.582-34.508l-.452-1.445-.271-.994c-2.349-8.221-2.891-12.737-2.078-17.164l.09-.271c.452-2.349,3.794-12.647,3.975-13.1l.361-1.355,1.174-3.975.361-1.265.361-1.265c2.168-7.588,3.162-12.647,3.252-18.429a43.871,43.871,0,0,0-7.137-24.481l-3.433-5.33-1.265-1.987c-1.536-2.349-2.71-4.065-3.975-5.872l-.813-1.174-.632-.813c-9.937-13.46-18.609-18.519-30.624-15.628h-.09c-4.968,1.716-8.311,8.04-8.4,16.8-.181,10.118,3.7,22.584,11.924,36.134l.9,1.265,1.084,1.626c4.878,7.317,6.5,10.75,6.775,14.905.361,5.782-2.981,11.744-11.2,19.513-14.815,13.189-9.847,32.521,14.363,57.544l.723.723Z"
                    transform="translate(-379.693 296.553)"
                  />
                  <path
                    id="Shape_31_"
                    d="M390.872-151.069c8.763-8.221,12.376-14.725,11.924-21.319-.271-5.33-2.439-9.214-9.034-18.88-8.04-13.1-11.744-25.113-11.563-34.779.181-7.859,2.981-13.37,6.866-14.725,11.111-2.62,19.151,2.258,28.817,15.628l.994,1.445,1.084,1.536c1.807,2.62,3.7,5.6,7.317,11.382a40.406,40.406,0,0,1,6.775,23.216c-.09,5.872-1.174,11.021-3.613,19.151l-1.626,5.511-.361,1.174c-.361,1.265-3.523,10.84-3.975,13.189-.994,5.149-.452,10.118,2.349,19.513l.361,1.084c3.433,11.473.9,22.223-7.679,32.431H406.5C382.109-120.355,377.05-138.783,390.872-151.069Z"
                    transform="translate(-379.935 296.33)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_32_"
                    d="M427.244-128.7l2.71,1.536.09.632c.452,2.258,1.174,4.878,2.078,8.04,3.252,11.021,1.265,21.5-5.962,31.347l-.452-.994c-1.084-2.258-1.265-2.981-3.613-9.124-2.258-9.214-.632-19.513,4.968-31.076Z"
                    transform="translate(-383.696 285.433)"
                  />
                  <path
                    id="Shape_33_"
                    d="M431.793-209.977l.271-.723.632.994h0c10.479,16.441,7.046,30.8,3.342,43.271-9.034-12.647-10.479-27.1-4.246-43.542Z"
                    transform="translate(-384.361 293.358)"
                  />
                  <path
                    id="Shape_34_"
                    d="M391.334-242.71c0,7.859,3.7,17.887,11.744,34.057l.994,1.987,4.968,9.756,10.66-28.817,1.626.632-11.111,30.172-.181-.09.632,1.265,1.626,3.162c1.716,3.342,2.71,5.511,3.613,7.769l.542,1.265.723,1.716c3.342,8.311,3.252,14.273.181,23.036l14.092-15.718,1.265,1.174-17.254,19.151c-.361.9-.723,1.807-1.174,2.71l-1.265,2.8-.9,1.987a41.093,41.093,0,0,0-3.162,9.937,46.06,46.06,0,0,0,0,14.544l17.254-14,1.084,1.355L409.22-118.226A105.371,105.371,0,0,0,415-98.081l.632,2.62-1.716-.181c-6.143-15.447-8.4-28.727-6.775-38.845l-18.248-16.17,1.174-1.355,17.525,15.447a50.955,50.955,0,0,1,3.252-9.395l.9-2.078.632-1.355c4.336-9.666,6.233-15.718,5.33-22.223l-20.777-14-1.084-2.62,21.41,14.273a43,43,0,0,0-1.9-5.782l-.542-1.355c-1.084-2.62-2.078-4.7-3.884-8.4l-1.265-2.62-6.053-12.015-1.445-2.891-19.874-9.3.723-1.626,17.887,8.311-.9-1.9c-6.866-14.092-10.027-23.307-10.208-30.8v-.452h1.536Z"
                    transform="translate(-379.945 296.46)"
                  />
                  <path
                    id="Shape_35_"
                    d="M499.071-97.4h9.937l.271-.09c17.525-9.395,25.023-18.88,21.952-28.637l-.09-.271c-1.626-4.788-1.716-8.311-.723-11.2.723-2.168,1.9-3.7,4.517-6.414l1.355-1.445.452-.452c.723-.813,1.265-1.355,1.807-2.078l.181-.181c6.143-8.13,6.956-16.26.271-28.275l-.271-.452c-3.342-5.962-3.523-11.292-1.265-16.17,1.626-3.613,4.517-6.685,8.221-9.576l.452-.361c8.04-5.962,6.685-14.815-6.053-26.107-7.588-7.5-6.685-14.634,4.7-27.372,2.71-2.71,3.342-8.672,1.084-12.737-2.8-4.969-8.853-6.324-17.345-2.981l-.452.181-.09.09c-5.24,3.433-9.666,14.815-11.2,29.269l-.181,1.9-.181,1.716c-1.355,11.744-3.071,14.725-10.389,19.784l-1.084.723c-9.576,6.324-7.317,19.422,3.071,26.288,1.536.994,1.445,3.252-.361,6.956a65.939,65.939,0,0,1-8.311,11.744l-.813.994c-3.884,4.607-5.33,8.311-4.878,12.286l.09.813.09.452c.271,1.807.9,3.252,3.071,7.588l1.626,3.162.09.09c1.987,1.9-2.349,8.4-8.853,13.37l-.361.271-.09.09c-9.576,10.479-6.5,22.674,8.672,35.954l.452.361Z"
                    transform="translate(-389.759 299.478)"
                  />
                  <path
                    id="Shape_36_"
                    d="M491.087-132.558l.542-.452.542-.452c6.956-5.51,11.382-12.466,8.311-15.9l-1.716-3.433-.452-.9c-1.716-3.613-1.987-4.607-2.258-6.5-.361-3.162.813-6.233,4.065-10.3l1.084-1.355.813-.994a60.832,60.832,0,0,0,7.679-11.2c2.258-4.517,2.439-7.95-.361-9.847-9.214-6.053-11.111-17.254-3.071-22.494l.542-.361.542-.361c8.221-5.6,10.027-9.214,11.473-22.584L519-241.5c1.445-14,5.6-24.933,10.208-28.095,7.679-3.162,12.647-2.078,14.815,1.807,1.807,3.252,1.265,8.04-.813,10.118-12.105,13.55-13.189,22.042-4.607,30.534l.723.632c11.111,10.027,12.105,17.074,5.782,21.952l-.181.181c-4.246,3.162-7.5,6.595-9.4,10.75-2.529,5.42-2.349,11.382,1.174,17.8l.181.361c6.414,11.473,5.782,18.7.09,26.2l-.271.361-.361.452c-.542.723-1.265,1.445-2.258,2.529l-.361.361c-3.071,3.162-4.336,4.878-5.24,7.5-1.174,3.342-1.084,7.227.542,12.2l.181.452c2.8,8.4-3.884,17.074-20.506,26.017h-8.492C485.4-112.142,482.506-123.073,491.087-132.558Z"
                    transform="translate(-389.995 299.228)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_37_"
                    d="M501.993-146.063,504.07-158.8l.452.9c7.679,15,8.763,22.674,4.155,28.456-10.569,15.718,6.956,33.153-8.311,42.006q-22.9-19.784-9.214-34.96l.361-.271c6.053-4.607,11.834-12.015,8.853-15l-1.355-2.71c-.723-1.445-1.355-2.71-1.9-3.975Z"
                    transform="translate(-389.97 288.342)"
                  />
                  <path
                    id="Shape_38_"
                    d="M509.757-184.341c-3.975-4.155-13.55-13.1-8.13-22.584,2.71-4.788,5.51-4.517,7.227-6.775v1.807c2.168,10.118,9.485,23.216,2.981,28.908A8.251,8.251,0,0,1,509.757-184.341Z"
                    transform="translate(-391.32 293.647)"
                  />
                  <path
                    id="Shape_39_"
                    d="M540.277-269.7l1.987.723c-10.66,9.395-18.519,29.9-17.164,43.181l.09.632.09.632,10.3-11.563.452,2.439-10.389,11.382.723,5.511.271,1.987a12.345,12.345,0,0,1,.181,1.716l.09.9v.361c.181,3.613-.271,5.962-1.9,8.4l-.723.813c-5.782,7.227-6.5,10.118-4.336,22.132l.271,1.445.542,2.981.09.361,15.086-12.828-.361,2.439-14.364,12.466c1.084,7.5-.181,11.744-3.7,17.074l-.9,1.355a21.01,21.01,0,0,0-1.987,3.252c-.994,1.987-1.9,3.975-2.62,5.872l29.9-10.027-.723,1.626-29.992,10.569c-2.078,5.872-2.62,11.021-1.174,15.086l.271.813c1.807,4.878,1.355,13.912-1.174,27.1l-.361,1.9-.361,1.9-1.716-.361.361-1.9c2.8-13.821,3.342-23.216,1.626-28l-.271-.813c-1.987-5.691-.632-12.737,3.071-20.958L498-169.969l1.445-.9,12.737,19.964.813-1.626a24.159,24.159,0,0,1,1.807-3.071l.9-1.265c3.7-5.33,4.878-9.3,3.7-17.074l-.09-.181.09-.09-.271-1.355-.452-2.258c-2.349-12.286-2.349-16.441,1.9-22.494l-13.55-16.622,1.355-1.084,13.1,16.17.632-.813.632-.813.723-.813c1.355-1.987,1.716-3.975,1.536-7.227l-.09-.723c-.181-2.168-.9-7.227-1.265-10.208l-.09-.09.09-.09-.271-2.168-.09-.813c-.813-7.859,1.174-17.8,5.059-26.74l-.181.723-1.807-15.447,1.716-2.078,1.265,14.363C532.328-260.666,535.941-266,540.277-269.7Z"
                    transform="translate(-391.126 299.059)"
                  />
                  <path
                    id="Shape_40_"
                    d="M481.518-99.666c6.053-8.582,4.968-17.8-3.252-27.462l-.271-.361c-3.884-4.517-4.788-8.04-3.252-11.2,1.174-2.439,3.613-4.517,7.95-7.137l1.265-.723c.994-.632,2.529-1.445,2.891-1.716l.632-.361a25.661,25.661,0,0,0,2.62-1.807l.813-.632c5.059-3.884,7.679-6.866,8.853-11.021,1.626-5.872-1.174-12.376-9.124-19.693l-.361-.361c-14.454-13.1-11.653-24.571,2.891-33.153l.452-.271c6.414-3.7,9.395-10.66,7.95-18.338-1.445-7.769-7.227-15.176-16.351-20.326l-.452-.271c-6.233-3.433-7.408-9.395-5.691-21.229l.271-1.9.361-2.258c1.174-7.95,1.355-11.2.271-14.634-1.536-5.059-5.691-7.679-13.279-7.679-13.279,0-20.958,6.595-22.765,17.435a38.414,38.414,0,0,0,5.872,26.2l.271.361c7.408,10.389,5.149,16.622-7.679,26.017l-.813.542c-13.189,9.395-16.17,22.494-7.227,34.96l.9,1.265.813,1.174c7.408,10.66,8.04,14.092,5.33,24.029l-.361.994c-.9,2.981-2.258,5.149-7.588,11.473l-1.807,2.168-.632.813c-4.426,5.42-6.595,8.853-7.679,12.737-1.536,5.6.09,10.931,5.51,15.9l.271.271c11.111,10.118,11.111,17.977.181,24.571l-3.523,2.078h55.466Z"
                    transform="translate(-383.858 302.2)"
                  />
                  <path
                    id="Shape_41_"
                    d="M433.788-101.222c9.124-7.046,8.221-15.9-2.529-25.836l-.361-.271c-5.059-4.517-6.5-9.034-5.149-13.912.994-3.613,3.162-6.866,7.588-12.376l2.71-3.252c5.059-6.143,6.5-8.311,7.408-11.563l.181-.723.181-.723c2.891-10.84,1.536-14.725-7.588-27.643-8.04-11.292-5.51-22.855,6.414-31.618l1.174-.9.813-.632c12.828-9.666,15.357-16.983,7.588-28.185l-.271-.361c-4.517-6.414-7.137-16.712-5.691-24.842,1.626-9.756,8.4-15.538,20.6-15.538,6.595,0,9.847,1.987,11.111,6.053l.09.361.09.361c.723,2.891.452,6.053-.542,13.008l-.542,3.7-.09.361c-.813,5.782-.994,9.666-.361,13.279a13.634,13.634,0,0,0,6.866,10.027l.361.181c17.525,9.576,20.958,27.914,8.763,34.96-7.137,4.065-11.924,9.214-13.46,15-1.807,6.866,1.174,14.183,9.395,21.681l.361.361c7.679,6.956,10.208,12.737,8.853,17.8-.994,3.7-3.613,6.5-8.763,10.389a17.743,17.743,0,0,1-2.529,1.626l-1.174.723-3.162,1.9c-4.878,2.981-7.769,5.33-9.214,8.4-1.9,3.975-.9,8.4,3.342,13.37l.271.271c7.588,8.763,8.853,16.712,3.975,24.21l-.181.271H433.788Z"
                    transform="translate(-384.098 301.949)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_42_"
                    d="M460.408-300.519l1.716-.181.361,4.336c.452,4.968.994,10.3,1.626,16.08l13.821-14,1.265,1.265-14.815,15,.632,5.782c.181,1.987.452,3.975.723,5.962l.723,5.872.9,7.137a58.947,58.947,0,0,1-.09,18.79l12.376-20.868,1.536.9L466.1-229.063c-.181.632-.361,1.355-.542,1.987l-.994,3.523c-2.258,8.221-2.71,13.912-.813,23.668l.361,1.716a57.3,57.3,0,0,1,.994,11.744l14.725-18.158,1.355,1.084-17.074,20.506.9-1.626a111.168,111.168,0,0,1-3.162,19.964l-1.716,7.046c-1.626,7.046-2.529,11.382-2.62,14.725l39.838-28.456.994,1.445-40.832,29.179a17.616,17.616,0,0,0,.723,3.794l.181.632.452,1.265a39.873,39.873,0,0,1,2.168,10.66l.09,1.355a76.6,76.6,0,0,1-2.258,21.771l-.271,1.084-1.716-.452a75.328,75.328,0,0,0,2.439-22.765L425.9-145.322l.9-1.445,32.25,21.139a38.44,38.44,0,0,0-1.987-8.853l-.361-.994a17.816,17.816,0,0,1-1.174-5.782v-.361c-.09-3.433.723-7.859,2.529-15.628l.542-2.439h0l-19.151-29.9,1.445-.994,18.248,28.456.452-1.9.542-2.258c2.981-13.1,3.794-21.952,1.987-31.708l-.361-1.807a56.594,56.594,0,0,1-.9-5.962l1.265,1.536-24.21-22.223,1.174-1.265,21.681,20.145a46.5,46.5,0,0,1,1.9-16.712l.271-.813.723-2.62.361-1.355.181-.813c1.987-7.679,2.349-13.46,1.265-23.036l-20.777-21.5-.452-2.349,20.777,21.048-.813-6.5c-.632-5.6-1.265-11.021-1.807-15.99l-.09-.542-.361-3.613c-.361-2.981-.632-5.781-.9-8.582l-.452-4.607Z"
                    transform="translate(-384.159 302.055)"
                  />
                  <path
                    id="Shape_43_"
                    d="M503.014-80v61.88H407.8V-80Z"
                    transform="translate(-382.41 280.727)"
                  />
                  <path
                    id="Shape_44_"
                    d="M410.2-77.5v57.363h90.878V-77.5Z"
                    transform="translate(-382.642 280.485)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_45_"
                    d="M421-12.7h69.559V8.077H421Z"
                    transform="translate(-383.685 274.223)"
                  />
                  <path
                    id="Shape_46_"
                    d="M409-15.116,502.046-37.7v22.584Z"
                    transform="translate(-382.526 276.639)"
                  />
                </g>
                <g id="Man" transform="translate(792.112 -409.564)">
                  <path
                    id="Shape_47_"
                    d="M431.42-193.7l-5.42,28,39.748,32.521L461.231-193.7Z"
                    transform="translate(-384.188 292.06)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_48_"
                    d="M427.049-165.883l4.155-25.655-2.258-.361L424.7-164.98l42.277,34.508-4.426-55.286-2.258.181,3.975,50.046Z"
                    transform="translate(-384.062 291.886)"
                  />
                  <path
                    id="Shape_49_"
                    d="M493.655-225.282l-8.582,2.891L473.6-262.5Z"
                    transform="translate(-388.788 298.709)"
                  />
                  <path
                    id="Shape_50_"
                    d="M487.459-223.2l3.794-1.265L482.4-241Z"
                    transform="translate(-389.638 296.631)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_51_"
                    d="M446.968-264.59l23.216,5.872.813,2.8.813,2.891c3.523,12.105,6.956,25.113,10.479,39.206,3.884,15.538-7.679,22.042-35.773,19.332l-.181,2.258c29.359,2.8,42.458-4.517,38.122-22.132-3.975-15.989-7.95-30.8-11.924-44.265l-.632-2.078L445.884-267.3,420.5-228.907l8.311,29.54,2.168-.632-8.04-28.546Z"
                    transform="translate(-383.656 299.173)"
                  />
                  <path
                    id="Shape_52_"
                    d="M431.25-200.071l-7.95-28L447.239-264.3l23.216,5.872.813,2.8.813,2.891c3.523,12.105,6.956,25.113,10.479,39.206,3.794,15.357-7.408,21.861-34.779,19.422l-.813-.09Z"
                    transform="translate(-383.927 298.883)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_53_"
                    d="M519.514-132.956C521.32-99.8,517.075-85.71,506.867-90.5c-3.613,5.42.632,14.183,12.647,26.2l2.71,13.55q32.521,8.13,28.908-33.424-3.659-41.6-11.744-60.525Z"
                    transform="translate(-391.882 287.325)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_54_"
                    d="M533.913-135.509l.542,2.258q12.466,55.286,5.149,74.437c-3.794,11.292-21.59,11.292-53.75-.632l-59.26-30.443L425.6-87.9l59.351,30.443.09.09c33.334,12.376,52.3,12.376,56.731-.723,5.24-13.46,3.342-39.387-5.6-77.96l-.542-2.349-25.023,6.775.09.9c1.9,26.288-.632,39.838-6.956,40.561-6.866.813-28.727-12.015-64.681-38.3l-1.355,1.807,2.168,1.626,3.162,2.258C476.55-98.562,496.6-87,504.012-87.9c8.04-.9,10.84-14.454,9.124-40.471l-.09-1.626Z"
                    transform="translate(-384.149 286.716)"
                  />
                  <path
                    id="Shape_55_"
                    d="M486.884-56.864,440-125.7l2.168,1.536c34.96,25.475,55.918,37.761,63.055,36.857A6.49,6.49,0,0,0,509.83-90.2l.271.723,10.3,41.464c-8.311-.813-18.88-3.523-31.8-8.221Z"
                    transform="translate(-385.54 285.489)"
                  />
                  <path
                    id="Shape_56_"
                    d="M531.076-140.022l-13.55,3.7-.452-3.252-.271-1.9c-4.336-30.8-8.943-47.788-13.46-50.227-.813-.452-1.626-.813-2.62-1.265l-.452-.181L499.1-193.5c-1.445-.542-3.342-1.174-3.794-1.265l-.361-.09c-2.62-.994-3.975-1.987-4.246-3.794s.9-4.065,3.7-7.046l.542-.542.09-.09c9.485-7.408,18.429-6.5,26.559,2.8l.361.452.361.813.361.723.361.723c4.878,10.75,10.84,28.817,18.158,54.292l.994,3.613-2.168.632A91.879,91.879,0,0,0,531.076-140.022Z"
                    transform="translate(-390.436 293.758)"
                  />
                  <path
                    id="Shape_57_"
                    d="M519.236-141.956l.452,2.981,9.756-2.62c2.349-.632,4.427-1.174,6.233-1.716l4.065-1.084-1.174-4.246-1.174-4.155c-6.595-22.765-12.2-38.845-16.622-48.33l-.271-.632c-7.317-8.492-15-9.3-23.487-2.71-2.71,2.8-3.7,4.7-3.613,5.691.09.632.9,1.265,2.8,1.987l.271.09,1.9.632c1.174.361,2.62.9,3.433,1.174l.632.271a18.987,18.987,0,0,1,2.349,1.174c5.239,2.891,9.937,19.874,14.454,51.492Z"
                    transform="translate(-390.7 293.524)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_58_"
                    d="M782.8,166.246,820.922,162l5.962,1.445q35.773,8.672,46.433,12.557l.9.361.723.271.542.271c6.775,2.891,8.4,6.5,3.7,9.756l-.452.361H790.207c-4.968,0-7.227-6.5-7.317-19.151v-1.626Z"
                    transform="translate(-418.668 257.686)"
                  />
                  <path
                    id="Shape_59_"
                    d="M795.538,143.4l.813.994c5.239,6.866,11.744,10.84,23.849,15.628l2.078.813-.994,1.174c-5.872,7.137-18.519,8.582-37.941,4.607l-.9-.181-5.24-5.059Z"
                    transform="translate(-418.127 259.483)"
                  />
                  <path
                    id="Shape_60_"
                    d="M421.166-164.6q-60.028,68.43-33.153,250.231l.181,1.445,2.168-.9q17.209-7.588,89.071-36.5l21.952-8.853-.09-.9C487.472-63.51,474.464-120.513,461.817-131.8q-17.48-15.583-36.767-31.166l-3.162-2.529Z"
                    transform="translate(-379.715 289.335)"
                  />
                  <path
                    id="Shape_61_"
                    d="M460.523-129.75c12.015,10.66,24.933,67.391,38.574,169.471L474.073,49.748l-15.267,6.233q-53.389,21.545-68.565,28.185Q364.5-92.667,421.227-160.645L422.4-162Q442.456-145.875,460.523-129.75Z"
                    transform="translate(-379.956 288.997)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_62_"
                    d="M674.3-4.964l6.233,6.956q15.177,17.074,59.8,66.849L792.1,126.475l-27.643,22.042L617.3,12.923,636.09-9.842l.723-.271C652.8-16.617,665.449-14.9,674.3-4.964Z"
                    transform="translate(-402.674 274.691)"
                  />
                  <path
                    id="Shape_63_"
                    d="M610.014,75.972l2.981,2.168,4.246,3.071q25.881,19.242,91.691,69.559l3.884,2.981L741.092,129.9,629.255,4.064l-.181-.09a14.928,14.928,0,0,0-3.433-1.987C619.318-.9,611.73-.9,603.6,3.612l-1.355.723Q586.12,13.278,500.436,55.014L388.6,100.362l.181.9c8.311,48.42,41.013,72.63,97.744,72.63,12.466,0,34.508-14.454,63.235-39.477C570.356,116.532,593.482,93.768,610.014,75.972Z"
                    transform="translate(-380.573 273.342)"
                  />
                  <path
                    id="Shape_64_"
                    d="M501.61,57.334l18.248-8.943q71-34.689,84.374-42.187l.271-.181c7.679-4.336,14.634-4.336,20.506-1.807.813.361,1.626.813,2.258,1.174l.271.181.181.181c.09,0,.09.09.181.09L738.11,129.874,713,151.1l-16.8-12.918-20.687-15.809q-47.291-36.044-64.229-48.33l-1.265-.9-.723.723c-16.531,17.887-40.019,40.922-60.8,59.08l-1.716,1.445-1.626,1.445C518.5,158.691,498,171.88,486.795,171.88q-82.116,0-95.124-68.475l-.271-1.355Z"
                    transform="translate(-380.844 273.1)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_65_"
                    d="M505.083-5.221c-1.807-1.355-1.355-2.62,1.174-3.975l.181-.09q4.065-2.033,78.412-33.153a4.44,4.44,0,0,1,4.878.9l27.191,26.2-1.536,1.987c-8.04,10.66-12.737,15.989-14.092,16.17h-.09q-2.033,0-63.416,22.223l-8.221-4.065Z"
                    transform="translate(-391.724 277.478)"
                  />
                  <path
                    id="Shape_66_"
                    d="M528.646,21.593,502.9-5.869l81.122-33.876a5.718,5.718,0,0,1,6.053,1.084l24.21,22.494,2.981,11.382-77.6,34.96-10.84-8.492Z"
                    transform="translate(-391.619 277.223)"
                  />
                  <path
                    id="Shape_67_"
                    d="M540.344,27.793,614.961-5.9l-2.439-8.853L588.854-36.8a3.326,3.326,0,0,0-3.613-.632L507.1-4.818,530.587,20.2Z"
                    transform="translate(-392.025 276.985)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_68_"
                    d="M539.741,4.892l9.3,18.519-9.214,5.059-6.956,3.794Q508.891,45.273,496.56,51.506c-10.027,4.968-24.842-13.731-43.723-56.731l-34.96-75.7-2.078.9,34.96,75.7,1.174,2.62c18.971,42.458,33.876,60.977,45.62,55.2l2.258-1.084,2.439-1.265q16.26-8.266,47.246-25.384l2.529-1.445-11.292-22.4-.994.452c-22.674,10.569-35.231,12.557-37.58,6.775l-.181-.452Q497.1-2.967,452.747-95.2l-2.078.994L463.588-67.2c21.952,45.71,34.057,71.546,36.405,77.237C503.245,17.63,516.434,15.642,539.741,4.892Z"
                    transform="translate(-383.202 282.541)"
                  />
                  <path
                    id="Shape_69_"
                    d="M453.208-5.022,418.7-79.64,451.582-93.1l12.557,26.107L473.9-46.577C489.7-13.514,498.557,5.366,500.454,10.245c3.071,7.679,16.26,5.691,39.567-5.059l9.3,18.519-9.214,5.059-6.956,3.794Q509.172,45.566,496.841,51.8C486.9,56.677,472.089,37.887,453.208-5.022Z"
                    transform="translate(-383.482 282.338)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_70_"
                    d="M552.2,9.057l2.439-.813,2.439-.813C596.1-5.4,616.248-14.611,616.248-19.128a13.053,13.053,0,0,1,.452-4.426,1.814,1.814,0,0,1,2.71-1.174,6.676,6.676,0,0,1,1.9,1.626l.361.361,1.536,1.626.452.542c.181.181.271.361.452.452l.452.452c5.782,5.872,7.679,14,1.716,17.977l-.361.271c-4.517,2.891-22.042,12.286-52.756,28.275l-9.576,5.059Z"
                    transform="translate(-396.383 275.757)"
                  />
                  <path
                    id="Shape_71_"
                    d="M555.8,10.682l9.124,18.429L577.3,22.7Q618.629,1.016,625-3.049l.361-.271c4.336-2.891,2.891-9.3-1.807-14.273l-.542-.542c-.452-.542-2.62-2.891-3.7-3.884l-.181-.181a13.764,13.764,0,0,0-.271,3.342c0,6.143-19.784,15.267-60.615,28.727Z"
                    transform="translate(-396.731 275.487)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_72_"
                    d="M792.822,165,754.7,169.246v.994c0,12.918,2.258,19.6,7.317,19.6h88.529l.271-.181c5.059-3.342,3.252-7.046-4.065-10.118l-.723-.271q-10.163-3.93-48.872-13.279Z"
                    transform="translate(-415.953 257.396)"
                  />
                  <path
                    id="Shape_73_"
                    d="M757.3,171.475l35.683-3.975,5.059,1.174,3.794.9q35.1,8.537,44.174,12.2l.542.271.542.271c4.968,2.168,5.691,3.7,3.071,5.6H762.359C759.2,187.826,757.39,182.5,757.3,171.475Z"
                    transform="translate(-416.204 257.154)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_74_"
                    d="M767.99,147.6,749.2,164.4l5.24,5.149.361.09q29.675,6.233,38.483-4.426l.994-1.174-1.445-.542c-6.685-2.62-14.454-7.5-23.487-14.634Z"
                    transform="translate(-415.421 259.077)"
                  />
                  <path
                    id="Shape_75_"
                    d="M768.338,150.9c8.4,6.685,15.9,11.473,22.4,14.273-5.6,5.33-17.254,6.233-34.87,2.62l-3.071-2.981Z"
                    transform="translate(-415.769 258.758)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_76_"
                    d="M426.4-200.609q-16.26-1.762-16.261-10.84c0-9.034-13.55-.9-16.26-9.937s-.9-18.067,7.227-19.874-2.71-5.42-6.324-10.84-.9-27.1,8.13-28.908,8.13-6.324,8.13-9.937,11.744-9.034,21.681-5.42,10.84-.9,14.454-3.613,13.55-5.42,18.067,3.613,5.42,11.744,9.937,11.744,13.55,18.971-5.42,22.584-3.613,6.324-4.517,8.13-.9,8.13-7.227,7.227-7.227-1.807-6.324,2.71.9,8.13-4.517,6.324-11.744,2.71-9.034,5.42.994,5.51,3.613,8.13,7.227.9,7.227,4.517.9,7.227-5.42,9.937,4.517,6.324-5.42,8.13Z"
                    transform="translate(-380.969 302.582)"
                  />
                </g>
                <g id="Woman" transform="translate(1127.266 -553.305)">
                  <path
                    id="Shape_77_"
                    d="M1040.609,69.863,1003.571,25.6,894.265,99.674H850l41.555-52.395L1003.571-27.7l37.038,64.139Z"
                    transform="translate(-425.169 276.124)"
                    stroke="#000"
                    stroke-width="2.5"
                  />
                  <path
                    id="Shape_78_"
                    d="M844.6,25.915l-.09-.9-.09-.994c-1.9-15.447-7.679-42.277-15.086-67.933l-.271-.813c-10.84-37.4-24.12-69.92-39.3-92.956L788.59-139.4l-.994.723q-40.245,31.708-57.454,72l-.542,1.265.181.452c52.847,118.611,92.233,169.29,118.792,151.584l2.349-1.536,3.071-2.078q37.4-25.339,111.113-80.038L970.8-1.276,952.821-37.23l-.994.542Q867.543,6.4,844.6,25.915Z"
                    transform="translate(-413.534 286.919)"
                  />
                  <path
                    id="Shape_79_"
                    d="M788.408-135.9c15,22.765,28.094,55.015,38.845,92.053l.452,1.716.723,2.439c7.5,26.649,13.1,54.021,14.454,67.842l.271,2.71,1.716-1.536q21-18.971,107.319-63.145l16.08,32.16-8.13,6.053L951.1,11.077Q881.726,62.3,847.669,85.062c-24.21,16.17-62.422-32.611-113.733-146.706L732.4-65.167Q749.067-104.6,788.408-135.9Z"
                    transform="translate(-413.805 286.581)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_80_"
                    d="M788.444-137.7A219.685,219.685,0,0,1,803.8-110.238q-7.995,41.871-65.584,62.874l.723-.271-1.807-3.794Q734.017-58.2,730.9-65.25,748.109-105.766,788.444-137.7Z"
                    transform="translate(-413.66 286.755)"
                    stroke="#000"
                    stroke-width="2.5"
                  />
                  <path
                    id="Shape_81_"
                    d="M572.161-182.4l25.023,33.876,6.685,48.42-55.015-6.5-.181-.09-62.874-15v-25.475l73.082,4.246Z"
                    transform="translate(-389.973 291.074)"
                  />
                  <path
                    id="Shape_82_"
                    d="M573.116-177,560.74-140.053,488.2-144.3v21.319l61.248,14.634,51.943,6.143-6.143-44.987Z"
                    transform="translate(-390.205 290.553)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_83_"
                    d="M568.6-143.834,579.079-175l1.445.994a78.8,78.8,0,0,0,7.227,4.517L604.1-147.357l.723,5.33c-2.891,0-5.962,2.258-9.034,3.252-8.4,1.174-15.267.9-20.416-.723C572.936-140.311,570.678-143.021,568.6-143.834Z"
                    transform="translate(-397.975 290.359)"
                    stroke="#000"
                    stroke-width="2.5"
                  />
                  <path
                    id="Shape_84_"
                    d="M690.323-154.224,673.25-134.982l-132.433,32.25L493.3,59.873h29.721l.361-.361q54.879-61.519,55.737-112.83l.723.09.723.09c7.769,1.174,21.59,2.078,36.044,2.168,22.042.09,44.265-1.626,64.41-5.6,24.933-4.878,45.62-13.008,60.706-24.662,17.616-13.641,27.1-31.8,27.1-54.834-1.445-26.468-27.282-32.521-76.7-18.609Z"
                    transform="translate(-390.698 289.096)"
                  />
                  <path
                    id="Shape_85_"
                    d="M766.9-135.738c0,22.223-9.124,39.748-26.2,53.027-14.725,11.382-35.141,19.332-59.712,24.21-20.055,3.975-42.1,5.691-63.958,5.6h-3.884c-14.815-.271-28.456-1.355-34.6-2.62l-1.355-.271v1.355q0,50.679-54.834,112.288H496.7L542.952-100.6l131.891-32.069,17.074-19.242C740.788-165.91,765.45-160.4,766.9-135.738Z"
                    transform="translate(-391.027 288.858)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_86_"
                    d="M580.553-231.1,571.7-198.037v.181a277.864,277.864,0,0,1,2.078,34.508v2.078l.361.361c8.221,8.221,19.964,10.389,52.214,6.956l1.987-.181c25.294-2.8,42.639,2.078,52.3,14.454l.452.542,95.214-24.3L675.5-220.26Z"
                    transform="translate(-398.275 295.781)"
                  />
                  <path
                    id="Shape_87_"
                    d="M582.511-228.4,675.2-217.831l95.485,53.84-88.349,22.584c-10.208-12.557-28-17.525-53.118-14.815l-2.71.271-1.807.181c-29.54,3.071-40.651,1.084-48.059-5.962l-.271-.181a275,275,0,0,0-1.987-34.237L574.2-197.6Z"
                    transform="translate(-398.516 295.52)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_88_"
                    d="M581.219-231v1.716c-.09,18.158-3.794,30.172-11.292,35.954-4.426,3.433-7.137,1.174-7.227-5.33v-1.174c.181-5.962,1.9-15.628,5.059-28.908L568.3-231Z"
                    transform="translate(-397.405 295.771)"
                  />
                  <path
                    id="Shape_89_"
                    d="M570.088-227.155l-.452,1.987c-2.891,12.2-4.336,20.958-4.336,26.2v.994c.09,4.336.994,5.059,3.342,3.252l.271-.181c6.775-5.24,10.208-16.441,10.389-33.7h-8.853Z"
                    transform="translate(-397.656 295.539)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_90_"
                    d="M525.451-133.771l40.832-10.66q6.5-158.269-53.75-158.269-53.8,2.168-12.918,121.863h25.836Z"
                    transform="translate(-389.605 302.7)"
                  />
                  <path
                    id="Shape_91_"
                    d="M517.175-265.727c-1.355,5.059-5.149,7.679-11.924,7.769h-.361c-12.828,0-16.622,5.962-9.937,18.79,7.769,15,15.538,19.513,31.979,15.718l1.355-.361v-37.67l-.271-.271q-2.846-3.659-4.878-4.607a6.932,6.932,0,0,0-5.24-.09l-.632.181Z"
                    transform="translate(-390.544 299.242)"
                  />
                  <path
                    id="Shape_92_"
                    d="M522.465-264.133a12.476,12.476,0,0,1,3.794,3.7v35.141c-15,3.433-21.771-.632-29.088-14.634l-.361-.632c-5.33-10.569-2.8-14.634,7.588-14.815h.632c7.5,0,12.286-2.891,14.183-8.492l.09-.271A4.139,4.139,0,0,1,522.465-264.133Z"
                    transform="translate(-390.775 299.002)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_93_"
                    d="M617-187.734q161.656,22.629,167.122,95.756a53.954,53.954,0,0,0,11.744-23.487q0-72.224-48.781-79.5Q698.3-202.279,617-187.734Z"
                    transform="translate(-402.652 292.524)"
                  />
                  <path
                    id="Shape_94_"
                    d="M796.448-96.778q-17.887-100.544-182.479-96.208l.09,2.258q163.689-4.2,180.311,95.214l.181.9h23.487l-.09-1.174c-7.137-107.59-86.09-152.758-236.048-135.595l.271,2.258c146.887-16.8,224.395,26.288,233.248,129.271l.271,3.162H796.448Z"
                    transform="translate(-399.26 296.15)"
                  />
                  <path
                    id="Shape_95_"
                    d="M584.7-229.151l1.626,1.716c17.435,18.158,27.01,29.63,28.817,34.779,109.036-2.62,169.742,29.359,181.576,96.208H815.87Q804.623-253.632,584.7-229.151Z"
                    transform="translate(-399.531 295.909)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_96_"
                    d="M830.031-31.141l.09-.361.361-2.439.181-1.084a51.419,51.419,0,0,0-.271-15.718c-1.355-8.582-4.7-18.429-10.479-29.63L819.1-82h21.229l.271,2.529c5.33,49.053,3.884,75.521-4.878,80.038l-.271.09h-.271c-1.9,0-2.62-.813-2.891-3.433l-.09-.452v-.452c-.09-1.626-.181-3.884-.181-9.3v-8.672c0-1.445-.542-2.168-1.445-2.349a3.268,3.268,0,0,0-2.62.813l-.271.271c-1.716,1.626-4.065.542-3.613-1.9.361-1.9,2.258-3.975,5.6-5.872l.361-.181Z"
                    transform="translate(-422.183 281.372)"
                  />
                  <path
                    id="Shape_97_"
                    d="M823-79.51c5.42,10.75,8.492,20.235,9.847,28.637a52.674,52.674,0,0,1,.271,16.351l-.09.542-.452,2.891-.09.361a1.146,1.146,0,0,1-.09.542v.9l-.632.271c-2.891,1.536-4.607,3.071-5.149,4.246a5.248,5.248,0,0,1,4.607-1.355c1.987.452,3.162,2.078,3.162,4.517v8.311c0,5.33.09,7.679.181,9.3l.181,1.9c.09.542.09.632.271.632,7.137-4.155,8.492-29.54,3.7-75.34l-.271-2.8H823Z"
                    transform="translate(-422.56 281.14)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_98_"
                    d="M523.491,81H493.68L477.6,104.036h29.811Z"
                    transform="translate(-389.181 265.62)"
                  />
                  <path
                    id="Shape_99_"
                    d="M519.618,83.5,506.61,102.019H482.4L495.408,83.5Z"
                    transform="translate(-389.645 265.378)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_100_"
                    d="M504.592,104h-32.7l-.361.542c-12.647,20.958-27.914,32.792-45.981,35.773l-2.168.361-.723.09a32.818,32.818,0,0,0-6.414,1.536c-1.174.452-1.807,1.174-1.265,2.168.271.632.9.9,1.9,1.174,2.349.542,7.679.9,15.267,1.084l2.439.09h1.626c3.523.09,6.956.09,11.021.09h47.065l.271-.09c3.252-1.626,4.878-5.42,4.969-11.292v-1.9c0-.632,0-1.445-.09-2.439l-.09-2.62v-1.084c-.361-9.937.542-15.357,4.426-21.681Z"
                    transform="translate(-383.11 263.397)"
                  />
                  <path
                    id="Shape_101_"
                    d="M501.074,106.5c-3.523,6.5-4.156,12.376-3.7,22.945l.181,3.433v2.439c0,5.149-1.174,8.311-3.433,9.576H443.981c-3.162,0-6.052-.09-9.034-.09l-2.62-.09-2.62-.09c-4.426-.181-7.95-.361-10.208-.632,1.174-.271,2.8-.542,4.878-.9l1.355-.181q27.914-4.336,47.336-35.683l.361-.632h27.643Z"
                    transform="translate(-383.566 263.155)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_102_"
                    d="M1008.718-39.3l13.731,33.334-29.9,15.718L974.3-25.93Z"
                    transform="translate(-437.181 277.245)"
                  />
                  <path
                    id="Shape_103_"
                    d="M993.87,6.99,1019.887-6.65,1007.782-36.1,977.7-24.356Z"
                    transform="translate(-437.51 276.936)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_104_"
                    d="M1009.4-39.767l.813-.542c16.351-10.389,28-6.5,33.966,11.292q8.672,25.746,12.918,64.41l.542,5.059-3.613-5.782C1038.94,10.28,1028.551-2.638,1023.4-3.993l-.723-.181L1022.5-4.9l-.181-.632c-1.445-6.866-5.51-17.525-12.015-31.889l-.632-1.445Z"
                    transform="translate(-440.573 277.893)"
                  />
                  <path
                    id="Shape_105_"
                    d="M1013.5-36.538l.632,1.355c5.6,12.647,9.214,22.223,10.75,28.727l.181.723c5.782,2.168,15.267,13.821,28.727,35.321l.813,1.355q-4.336-35.1-12.286-58.9c-5.33-15.989-14.906-19.513-29.269-10.931l-.452.271Z"
                    transform="translate(-440.883 277.645)"
                    fill="#fff"
                  />
                  <path
                    id="Shape_106_"
                    d="M401.021-150c-3.7,0-5.6,1.9-3.433,4.065,1.265,1.265,3.884,2.258,6.685,2.439-.09.452-.723.994-2.168,1.626a44.485,44.485,0,0,1-10.75,2.439l-1.9.271-.813.09a29.075,29.075,0,0,0-7.227,1.716c-2.349,1.084-2.258,3.071.271,3.975,3.794,1.265,14.363,1.536,29.811.632h.542c5.059.361,25.836,5.149,61.88,14.273l3.7.9V-143.5l-59.712-2.168C410.958-148.555,405.358-150,401.021-150Z"
                    transform="translate(-379.722 287.943)"
                  />
                  <path
                    id="Shape_107_"
                    d="M475.866-141.1v20.868l-6.775-1.716-7.95-1.987c-29.269-7.317-45.62-10.931-49.324-10.931l-1.807.09-1.174.09c-13.189.723-22.4.452-25.836-.542a34.036,34.036,0,0,1,7.046-1.536l1.9-.271,1.084-.181a40.97,40.97,0,0,0,10.3-2.439c2.981-1.265,4.246-3.071,3.162-5.24l-.271-.632h-.723c-2.62,0-5.059-.813-6.053-1.807.09,0,.09,0,.181-.09a6.728,6.728,0,0,1,1.626-.181c3.884,0,9.214,1.355,15.809,3.975l.723.271Z"
                    transform="translate(-380.039 287.711)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="seo-services_content py60" id="seo-audits">
            <div className="col-md-4">
              <img alt="alt text" src={seoAudits} />
              <h2>SEO Audits</h2>
              <p>
                As the first step of our SEO operation, the existing SEO
                performance of your brand’s website is examined by our  SEO
                experts and their audit tools. This audit helps us and you to
                see the strong and weak points.
              </p>
            </div>
            <div className="col-md-8 content-list">
              <div className="col-md-6 audits-info">
                <h3>Keyword Analysis</h3>
                <p>
                  Using a variety of software such as Google Search Console,
                  Ahrefs, SEMrush, and SEOmonitor; we analyze the keywords that
                  make up most of your traffic. We also determine those that are
                  not making any progress and those that need to be improved.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Competition Analysis</h3>
                <p>
                  Competition analysis is critical to enable you to get an upper
                  hand in competition in your industry.{" "}
                </p>
                <p>
                  First, we determine who are your competitors within Google
                  search results, and then we observe situations that prevent
                  you from exceeding your competitors.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Technical Analysis</h3>
                <p>
                  The quality of the user experience of a website plays a key
                  role in SEO success.{" "}
                </p>
                <p>
                  By doing technical analysis, we determine whether your website
                  is mobile-friendly, what kind of certification it has, what is
                  the uploading speed, and whether there is any duplicated
                  content. We review such issues that may create problems in
                  SEO, report the weaknesses, and create a plan to overcome such
                  problems.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Page-Level Analysis</h3>
                <p>
                  We check all aspects of the content available on a website.
                  Our analysis range from meta descriptions to URL, from keyword
                  frequency to link building. Our aim is to optimize each and
                  every page on the website, not just important or featured
                  ones.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>
                  Content Quality <br /> Analysis
                </h3>
                <p>
                  Consistent, useful and accurate information of the website
                  content is among the most important elements that form the
                  basis of good SEO performance.
                </p>
                <p>
                  We evaluate the originality and quality of the content and
                  identify the points that negatively affect SEO performance.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>
                  User Experience
                  <br />
                  Analysis
                </h3>
                <p>
                  We measure the time that your users spend on the website and
                  their bounce rates, and exam their experience in accessing the
                  content they are looking for. In order o reveal the general
                  situation, we make sense of these UX statistics.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>
                  Full Backlink Profile
                  <br />
                  Analysis
                </h3>
                <p>
                  All links are important, but some links are more important.
                </p>
                <p>
                  By viewing your backlink profile, we look at the number of
                  backlinks from websites with similar niches, the type of
                  backlink, the anchor text used, and many more in order to
                  enhance your performance.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>
                  A Tailored SEO Strategy Plan <br />- Just For You!
                </h3>
                <p>
                  We evaluate the analysis data for permanent success and
                  develop the right strategy specifically designed for your
                  brand.
                </p>
                <p>
                  We increase the conversions rate by creating a steady traffic
                  increase on your website with a strategic plan that focuses on
                  feasible goals.
                </p>
              </div>
            </div>
          </div>
          <div className="seo-services_content py60" id="on-pageSeo">
            <div className="col-md-4">
              <img alt="alt text" src={onePageSeo} />
              <h2>On-Page SEO</h2>
              <p>
                As a result of the analysis, we determine the points that do not
                comply with the SEO standards on all your web pages and make the
                necessary improvements at the levels you see on the right in the
                user experience focus.
              </p>
            </div>
            <div className="col-md-8 one-page-seo content-list">
              <div className="col-md-6 audits-info">
                <h3>Optimization</h3>
                <p>
                  - Page Title Editing
                  <br />
                  - Identification of Missing Page Titles
                  <br />
                  - Very Long / Short Page Titles
                  <br />
                  - Duplicated Page Titles
                  <br />
                  - Missing Meta Descriptions
                  <br />
                  - Very Long / Short Meta Descriptions
                  <br />
                  - Duplicate Meta Descriptions
                  <br />- Subtitle Hierarchy
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Keywords</h3>
                <p>
                  - Keyword Analysis
                  <br />
                  - Competition analysis
                  <br />
                  - Low Search Volume Keyword Searches
                  <br />
                  - Domain Authority Comparison <br />
                  - Long Tail Keyword Selection
                  <br />
                  - Sub-Page Keyword Hierarchy
                  <br />
                  - Keyword Frequency
                  <br />- Incorrect Anchor Text Link
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Indexing</h3>
                <p>
                  - Identification of valuable Content Blocked by Robots.txt
                  File
                  <br />
                  - Unlisted URL  in Robots.txt File or Missing XML Sitemap{" "}
                  <br />
                  - XML Sitemap Pages blocked by robots.txt <br />
                  - Incorrect / Incomplete / Outdated XML Sitemap
                  <br />
                  - Missing XML Video and Visual Sitemap <br />
                  - Low Page Speed Score <br />
                  - Disused Webmaster Tools
                  <br />- False Canonical Tags
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>URL Structure</h3>
                <p>
                  - Unnecessary Subdomains
                  <br />
                  - Unnecessary Directories
                  <br />
                  - Generic Directory Structure
                  <br />
                  - Dynamic URL Issues
                  <br />
                  - Tracking URL Parameters
                  <br />- Session IDs in the URL
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Linking</h3>
                <p>
                  - Internal Linking Problems
                  <br />
                  - Insufficient Anchor Text
                  <br />
                  - Missing Breadcrumbs
                  <br />
                  - Incorrect Breadcrumbs
                  <br />
                  - Schema Missing Breadcrumbs
                  <br />
                  - A Sitemap that is Unavailable for the Users
                  <br />
                  - Unnecessary No-Follow Links in Internal Linking JavaScript
                  Navigation
                  <br />
                  - Images Used in Navigation
                  <br />- Flash Navigation
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Site</h3>
                <p>
                  - Site With Page Errors
                  <br />
                  - Broken Page Links
                  <br />
                  - Non-customized 404 Error Page
                  <br />- Pages Not Returning to 404
                </p>
              </div>
            </div>
          </div>
          <div className="seo-services_content py60" id="off-pageSeo">
            <div className="col-md-4">
              <img alt="alt text" src={offPageSeo} />
              <h2>Off-Page SEO</h2>
              <p>
                We increase the traffic flow of your brand’s website by working
                at the levels you see on the right, in order for your website to
                be recognized as a reliable medium by a variety of search
                engines and thus to be ranked high by them.
              </p>
            </div>
            <div className="col-md-8 content-list">
              <div className="col-md-6 audits-info">
                <h3>Link Construction</h3>
                <p>
                  The number and quality of links from other websites to your
                  website are very important for SEO performance.
                </p>
                <p>
                  We are building links that will make you stand out in the
                  competition and increase the traffic.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>SEO Campaigns</h3>
                <p>
                  We organize SEO campaigns according to a well constructed
                  strategic plan. As a result of that, your potential customers
                  can reach your website seamlessly, have a good experience and
                  become your customers.
                </p>
                <p>
                  We develop projects that benefit you by developing micro sites
                  and tools in campaigns.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>
                  Anchor Text
                  <br />
                  Optimization
                </h3>
                <p>
                  Using correct and suitable anchor text is very important for
                  your website. This will give you a strong and natural backlink
                  profile.{" "}
                </p>
                <p>
                  We make strategic anchor text optimizations and increase your
                  SEO performance.
                </p>
              </div>
              <div className="col-md-6 audits-info">
                <h3>Content Distribution</h3>
                <p>
                  We use the right channels and media formats to bring your
                  website content together with the right audience.
                </p>
                <p>
                  We distribute the produced content on 12 different content
                  platforms such as Medium. In this way, we provide direct
                  access to the audience in those channels.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="seo-tools container py60" id="seo-tools">
          <h2>The SEO tools we use</h2>
          <p>
            Professional tools we use to make your brand stand out in
            competition.
          </p>
          <img alt="alt text" src={seoTools}></img>
        </div>

        <div className="team">
          <div className="py60">
            <h2 style={{ textAlign: "center" }}>
            Our SEO experts 
              <br /> who can help you.
            </h2>
          </div>
          <Teams />
        </div>

        <div className="hiring" style={{ marginTop: -80 }}>
          <h2>Frequently Asked Questions</h2>
          <SeoSss />
        </div>
      </Layout>
    )
  }
}
export default Seo
